@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


@layer components {
    .collapsee.collapse {
        visibility: visible;
    }
}

@layer components {
    .skew-text {
        @apply before:content-[''] before:absolute before:inset-0 before:-skew-x-12 before:-z-10 px-4 py-2 italic relative inline-block
    }
}
